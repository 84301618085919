import { Typography, Link, Container } from '@mui/material'

const Footer = ({t}) => {
	return <footer className='footer'>
		<Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
			<div style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column"
			}}>
				<Typography
					varient='body2'
					color='textSecondary'
					margin='auto'
					align="center"
				>
					{t('footer.1')}
					{new Date().getFullYear()}
				</Typography>
			</div>
		</Container>
	</footer>
}

export default Footer
