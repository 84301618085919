import '../css/movie.css';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	Typography,
	Paper,
	Grid,
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Button
} from '@mui/material';
import { Container } from '@mui/system';
import Loader from './Loader';
import Notification from './Notification';
import browsingService from '../services/browsingService';
import VideoPlayer from './VideoPlayer';
import Comments from './movie/Comments';
import PathNotExists from './PathNotExists';
import bookingService from '../services/bookingService';
import { setProfileData } from '../reducers/profileReducer';
import { changeSeverity } from '../reducers/severityReducer';
import { changeNotification } from '../reducers/notificationReducer';
import roosterGif from '../images/kukko_kana.gif';

const MoviePage = ({ t }) => {
	const [imdbData, setImdbData] = useState(null);
	const [festivalData, setFestivalData] = useState(null);
	const [show, setShow] = useState(false);
	const params = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const movie_id = params.id;

	const profile = useSelector((state) => state.profile)
	const language = useSelector((state) => state.language)
	const films = useSelector((state) => state.films)
	const userBookings = profile?.bookings || []

	useEffect(() => {
		const fetchData = async (movie_id, t) => {
			const data = await browsingService.getFestivalData({ movie_id: movie_id })
			setFestivalData(data);
			const imdb_id = data.imdb_id;

			browsingService.getIMDbData({ imdb_id: imdb_id }).then((data) => {
				const imdbArray = [
					'Year',
					'Rated',
					'Released',
					'Runtime',
					'Genre',
					'Director',
					'Writer',
					'Actors',
					'Plot',
					'Language',
					'Country',
					'Awards',
					'imdbRating',
					'imdbVotes',
				];
				const ytsArray = [
					'year',
					'empty_value',
					'empty_value',
					'runtime',
					'genres',
					'empty_value',
					'empty_value',
					'empty_value',
					'description_full',
					'language',
					'empty_value',
					'empty_value',
					'rating',
					'empty_value',
				];

				if (data.error) {
					setImdbData('error');
				} else {
					const asArray = Object.entries(data);
					const filtered = asArray.filter(
						([key, value]) =>
							(typeof value === 'string' ||
								typeof value === 'number') &&
							value !== 'N/A' &&
							(imdbArray.includes(key) || ytsArray.includes(key))
					);
					let backToNormal = Object.fromEntries(filtered);
					for (const [key, value] of Object.entries(backToNormal)) {
						if (key === 'description_full') {
							backToNormal['Plot'] = value;
							delete backToNormal['description_full'];
						}
						if (key === 'genres') {
							backToNormal['Genre'] = value;
							delete backToNormal['genres'];
						}
						if (key === 'language') {
							backToNormal['Language'] = value;
							delete backToNormal['language'];
						}
						if (key === 'rating') {
							backToNormal['imdbRating'] = value;
							delete backToNormal['rating'];
						}
						if (key === 'runtime') {
							backToNormal['Runtime'] = value + ' min';
							delete backToNormal['runtime'];
						}
						if (key === 'title') {
							backToNormal['Title'] = value;
							delete backToNormal['title'];
						}
						if (key === 'year') {
							backToNormal['Year'] = value;
							delete backToNormal['year'];
						}
					}
					if (t('imdb_data.0') !== 'Title') {
						let translatedKeys = filtered.map((key) => {
							let keyIndex = imdbArray.indexOf(key[0]);
							if (keyIndex === -1) {
								keyIndex = ytsArray.indexOf(key[0])
							}
							let translation = `${t(`imdb_data.${keyIndex}`)}`;
							return translation;
						});
						const replaceKeys = (translatedKeys, backToNormal) => {
							const keys = Object.keys(backToNormal);
							const res = {};
							for (let a in translatedKeys) {
								res[translatedKeys[a]] = backToNormal[keys[a]];
								backToNormal[translatedKeys[a]] =
									backToNormal[keys[a]];
								delete backToNormal[keys[a]];
							}
						};
						replaceKeys(translatedKeys, backToNormal);
					}

					setImdbData(backToNormal || '');
				}
			});
		}
		fetchData(movie_id, t);
	}, [movie_id, t]);

	if (!imdbData || !festivalData) return <Loader />;

	let imdb_id = festivalData.imdb_id;
	const booked = userBookings.find((booking) => booking === festivalData.id)

	const makeBooking = (film_id) => {
		bookingService.makeBooking(film_id).then((response) => {
			if (response === 'User not signed in') {
				dispatch(changeSeverity('error'));
				dispatch(changeNotification(`${t('timetable.1')}`));
				navigate('/login');
			} else {
				dispatch(setProfileData({ ...profile, bookings: [...profile.bookings, film_id] }))
				const film = films.find((movie) => movie.id === film_id)
				dispatch(changeSeverity('success'));
				dispatch(changeNotification(`${t('timetable.11')} ${language === 'fi' ? film.title.toUpperCase() : film.title_en.toUpperCase()}. ${t('timetable.13')}`));
			}
			console.log(response);
		})
	};

	const cancelBooking = (film_id) => {
		bookingService.cancelBooking(film_id).then((response) => {
			if (response === 'User not signed in') {
				dispatch(changeSeverity('error'));
				dispatch(changeNotification(`${t('timetable.2')}`));
				navigate('/login');
			} else {
				dispatch(setProfileData({ ...profile, bookings: profile.bookings.filter((booking) => booking !== film_id) }))
				const film = films.find((movie) => movie.id === film_id)
				dispatch(changeSeverity('success'));
				dispatch(changeNotification(`${t('timetable.12')} ${language === 'fi' ? film.title.toUpperCase() : film.title_en.toUpperCase()}.`));
			}
			console.log(response);
		})
	};

	if (imdbData === 'error') {
		return (
			<Box
				container="true"
				style={{
					direction: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					display: 'flex',
					flexWrap: 'wrap',
					width: '100%',
					height: '100%',
				}}
			>
				<PathNotExists />
			</Box>
		);
	} else {
		return (
			<Box>
				<Box >
					<h2 className="movie-title">
						{language === 'fi' ? festivalData.title : festivalData.title_en} ({festivalData.year})
					</h2>
					{festivalData.title.includes('De Gallo') &&
						<img src={roosterGif}
							style={{ width: '90%', maxWidth: '520px', marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
							alt="rooster or egg..." />}
					{!festivalData.title.includes('De Gallo') && <VideoPlayer imdb_id={imdb_id} festivalData={festivalData} t={t} />}
					<h5 className="comment" onClick={() => setShow(!show)}>
						{t('movie.0')} {festivalData.comments !== 0 && `(${festivalData.comments})`} {'▼'}
					</h5>
				</Box>
				{show && <Comments movieId={imdb_id} t={t} />}
				<Container maxWidth="md" sx={{ pt: 5, pb: 5 }}>
					<Paper elevation={10} sx={{ padding: 3 }}>
						{Object.keys(imdbData).map((key) => (
							<Grid
								container
								key={`container${key + imdbData[key]}`}
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									mb: 2,
								}}
							>
								<Typography
									sx={{
										width: 'fit-content',
										fontWeight: '700',
									}}
								>
									{`${key || ''}:`}
								</Typography>
								<Grid item xs={12} sm={10}>
									<Typography
										sx={{
											width: 'fit-content',
											wordBreak: 'break-word',
										}}
									>
										{festivalData.title.includes("LATE NIGHT SPECIAL")
											? '???'
											: imdbData[key]}
									</Typography>
								</Grid>
							</Grid>
						))}
					</Paper>
					<Paper elevation={10} sx={{ padding: 3, mt: 3 }}>
						{/* <Typography
							sx={{ display: 'flex', justifyContent: 'center' }}
						>
							{t('movie.1')}
						</Typography> */}
						<Grid
							container
							key="container"
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								mb: 2,
							}}
						>
							<Typography
								sx={{
									width: 'fit-content',
									fontWeight: '700',
								}}
							>
								{t('browsing.14')}:
							</Typography>
							<Grid item xs={12} sm={10}>
								<Typography
									sx={{
										width: 'fit-content',
										wordBreak: 'break-all',
									}}
								>
									{festivalData.screenday} {festivalData.screentime}
								</Typography>
							</Grid>
						</Grid>
						{booked &&
							<Button variant="contained" style={{ maxWidth: '20vw', backgroundColor: "#ea5b0c" }}
								onClick={() => cancelBooking(festivalData.id)} > {t('timetable.9')}
							</Button>}
						{!booked && festivalData.freeseats > 0 &&
							<Button
								variant="contained" color="primary" style={{ maxWidth: '20vw', backgroundColor: "#222264" }}
								onClick={() => makeBooking(festivalData.id)} > {t('timetable.8')}
							</Button>}
						{!booked && festivalData.freeseats === 0 &&
							<Button disabled variant="contained" style={{ maxWidth: '20vw' }}
							> {t('timetable.10')}
							</Button>}
					</Paper>
					<Notification />
				</Container>
			</Box>
		);
	}
};

export default MoviePage;
