import { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Typography, Button } from '@mui/material';
import '../css/style.css';
import useFetch from '../hooks/useFetch';
import LoaderDots from './LoaderDots';
// import SearchBar from './browsing/SearchBar';
import MovieList from './browsing/MovieList';
import { useSelector, useDispatch } from 'react-redux';
import Loader from './Loader';
import movieService from '../services/movieService';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Grid from '@mui/material/Grid';
import browsingService from '../services/browsingService';
import { setPage, increasePage } from '../reducers/pageReducer';

const Browsing = ({ t }) => {
	const [booked, setBooked] = useState([]);
	const [movies, setMovies] = useState([]);
	const [loading, setLoading] = useState(true);
	const loader = useRef();
	const profileData = useSelector((state) => state.profile);
	const page = useSelector((state) => state.page);

	useEffect(() => {
		browsingService.getMovies().then((response) => {
			setMovies(response)
			setLoading(false)
		})
		setBooked(profileData?.bookings);
	}, [profileData?.bookings, setBooked]);

	const [browsingSettings, setBrowsingSettings] = useState({
		submittedQuery: '',
		query: '',
		genre: null,
		sort_by: null,
		order_by: 'desc',
		imdb_rating: null,
	});

	if (loading || !profileData || !page) return <Loader />;

	return (
		<Container
			sx={{
				maxWidth: 1080,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			{/* <SearchBar
				t={t}
				browsingSettings={browsingSettings}
				setBrowsingSettings={setBrowsingSettings}
			/> */}
			<MovieList movies={movies} booked={booked} t={t}/>
			{/* {profileData?.infinite_scroll === 'YES' ? (
				<Infinite
					loader={loader}
					watched={watched}
					browsingSettings={browsingSettings}
				/>
			) : (
				<Paginated
					watched={watched}
					browsingSettings={browsingSettings}
				/>
			)} */}
		</Container>
	);
};

export default Browsing;
