import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Typography, Paper, Box, Grid, Avatar, Button } from '@mui/material';
import { Container } from '@mui/system';
import Notification from './Notification';
import { useSelector, useDispatch } from 'react-redux';
import bookingService from '../services/bookingService';
import adminService from '../services/adminService';
import { changeSeverity } from '../reducers/severityReducer';
import { changeNotification } from '../reducers/notificationReducer';
import Loader from './Loader';

const BookingsTable = ({ movies, reservations, t }) => {
	const navigate = useNavigate();
	const language = useSelector((state) => state.language);

	return (
		<TableContainer component={Paper}>
			<Table sx={{ width: '100%' }} aria-label="simple table">
				<TableHead>
					<TableRow  >
						<TableCell sx={{ paddingLeft: "1vw", paddingRight: 0 }}>{t('admintable.1')}</TableCell>
						<TableCell align="left" sx={{ padding: "3px" }}>{t('admintable.2')}</TableCell>
						<TableCell align="left" sx={{ padding: "3px" }}>{t('admintable.3')}</TableCell>
						<TableCell align="left" sx={{ padding: "3px" }}>{t('admintable.4')}</TableCell>
						<TableCell align="left" sx={{ padding: "3px" }}>{t('admintable.5')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{movies.map((movie) => {
						let film_reservations = reservations.filter((reservation) => reservation.film_id === movie.id);
						return (<TableRow
							key={movie.id}
							sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<TableCell component="th" scope="row" sx={{ paddingLeft: "1vw", paddingRight: 0 }}>
								{movie.screenday}
							</TableCell>
							<TableCell align="left" sx={{ padding: "3px" }}>{movie.screentime}</TableCell>
							<TableCell align="left" sx={{ padding: "3px" }}
								style={{ cursor: 'pointer' }} onClick={() => navigate(`/movie/${movie.id}`)}>
								{language === 'fi' ? movie.title.toUpperCase() : movie.title_en.toUpperCase()}
							</TableCell>
							<TableCell align="left" sx={{ padding: "3px" }}>
								{film_reservations.map((reservation, i) => ((i ? ', ' : '') + reservation.username))}
							</TableCell>
							<TableCell align="left" sx={{ padding: "3px" }}>{20 - movie.freeseats}</TableCell>
						</TableRow>)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

const AdminPage = ({ t }) => {
	const [movies, setMovies] = useState([]);
	const [reservations, setReservations] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const profile = useSelector((state) => state.profile)

	useEffect(() => {
		bookingService.getFilms().then((response) => {
			setMovies(response);
			setIsLoading(false);
		});
		adminService.getReservations().then((response) => {
			setReservations(response);
		});
	}, []);

	const sendEmails = (date) => {
		if (window.confirm(t('admin.4'))) {
			adminService.sendEmails(date).then((response) => {
				dispatch(changeSeverity('success'));
				dispatch(changeNotification(response));
			})
		}
	}

	if (isLoading || movies.length === 0) return (<Loader />);

	if (profile?.username !== 'SeanTroy' && profile?.username !== 'Pekka' && profile?.username !== 'Nina') navigate('/');

	return (
		<Container maxWidth="md" sx={{ pt: 5, pb: 5 }}>
			<BookingsTable movies={movies} reservations={reservations} t={t} />
			<Paper elevation={10} sx={{ padding: 3, pl: 0, pr: 0, mt: 3 }}>
				<Container
					sx={{
						pb: '5',
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<Button variant="contained" style={{ backgroundColor: "#ea5b0c", marginRight: 5 }}
						onClick={() => sendEmails(`${process.env.REACT_APP_FESTIVALDAY1}`)}
					> {t('admin.1')}
					</Button>
					<Button variant="contained" color="primary" style={{ backgroundColor: "#222264", marginRight: 5 }}
						onClick={() => sendEmails(`${process.env.REACT_APP_FESTIVALDAY2}`)}
					> {t('admin.2')}
					</Button>
					<Button variant="contained"
						onClick={() => sendEmails(`${process.env.REACT_APP_FESTIVALDAY3}`)}
					> {t('admin.3')}
					</Button>
				</Container>
			</Paper>
			<Notification />
		</Container >
	);
}

export default AdminPage;
