import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	Typography,
	Button,
	Paper,
	createTheme,
	Box,
	Grid,
	Avatar,
	Table,
	TableBody,
	TableRow,
	TableCell
} from '@mui/material';
import AspectRatio from '@mui/joy/AspectRatio';
import { Container } from '@mui/system';
import Notification from './Notification';
import { setProfileData, getProfileData } from '../reducers/profileReducer';
import { setFilms } from '../reducers/filmsReducer';
import { changeSeverity } from '../reducers/severityReducer';
import { changeNotification } from '../reducers/notificationReducer';
import profileService from '../services/profileService';
import bookingService from '../services/bookingService';
import Loader from './Loader';

const theme = createTheme({
	palette: {
		primary: {
			main: '#222264',
		},
	},
});

const deleteTheme = createTheme({
	palette: {
		primary: {
			main: '#ea5b0c',
		},
	},
});

const Profile = ({ t }) => {
	const [isLoading, setLoading] = useState(true);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const profileData = useSelector((state) => state.profile);
	const films = useSelector((state) => state.films);
	const language = useSelector((state) => state.language);

	useEffect(() => {
		const getData = async () => {
			await dispatch(getProfileData());
			setLoading(false);
		};
		getData();
	}, [dispatch]);

	if (isLoading || !profileData.id || !films) {
		return <Loader text="Getting profile data..." />;
	}

	const profile_pic = profileData.profile_pic['picture_data'];

	const bookings = films.filter((film) => profileData.bookings.includes(film.id));

	const profilePictureStyle = {
		width: '100%',
		aspectRatio: '1/1',
		borderRadius: '50%',
		objectFit: 'cover',
	};

	const deleteUser = () => {
		if (window.confirm(`${t('del.1')}`)) {
			if (window.confirm(`${t('del.2')}`)) {
				if (window.confirm(`${t('del.3')}`)) {
					navigate('/deleteuser');
				}
			}
		}
	};
	const setProfilePicture = async (event) => {
		const image = event.target.files[0];
		if (image.size > 5242880) {
			dispatch(changeSeverity('error'));
			dispatch(changeNotification(`${t('profile.0')}`));
		} else {
			let formData = new FormData();
			formData.append('file', image);
			profileService.setProfilePic(formData, language).then((result) => {
				if (result === true) {
					dispatch(getProfileData());
					dispatch(changeSeverity('success'));
					dispatch(changeNotification(`${t('profile.8')}`));
				} else {
					dispatch(changeSeverity('error'));
					dispatch(changeNotification(result));
				}
			});
		}
		event.target.value = '';
	};

	const handleCancelDelete = (film) => {
		if (window.confirm(`${t('profile.11')} ${language === 'fi' ? film.title.toUpperCase() : film.title_en.toUpperCase()}?`)) {
			bookingService.cancelBooking(film.id).then((response) => {
				if (response === 'User not signed in') {
					dispatch(changeSeverity('error'));
					dispatch(changeNotification(`${t('timetable.2')}`));
					navigate('/login');
				} else {
					dispatch(setProfileData({ ...profileData, bookings: profileData.bookings.filter((booking) => booking !== film.id) }))
					dispatch(setFilms(films.map((movie) => movie.id === film.id ? { ...movie, freeseats: movie.freeseats + 1 } : movie)))
					dispatch(changeSeverity('success'));
					dispatch(changeNotification(`${t('timetable.12')} ${language === 'fi' ? film.title.toUpperCase() : film.title_en.toUpperCase()}.`));
				}
				console.log(response);
			})
		}
	};

	return (
		<Container maxWidth="md" sx={{ pt: 5, pb: 5 }}>
			<Paper elevation={10} sx={{ padding: 3 }}>
				<Grid
					sx={{
						display: 'flex',
						alignContent: 'center',
						alignItems: 'center',
						justifyContent: 'center',
						mb: 2,
					}}
				>
					<Box sx={{ width: '100%', minWidth: '50px', maxWidth: '200px', display: 'inline-block' }}>
						<AspectRatio ratio={1}>
							<Avatar
								src={profile_pic}
								alt="profile"
								style={profilePictureStyle}
							/>
						</AspectRatio>
					</Box>
					<Box sx={{ width: 'fit-content', ml: 5 }}>
						<Typography variant="h2" sx={{
							fontSize: '250%', whiteSpace: 'pre-line',
							overflowWrap: 'break-word',
							wordWrap: 'break-word',
							hyphens: 'auto',
						}}>
							{profileData.username}
						</Typography>
					</Box>
				</Grid>
				<Container
					sx={{
						pb: '5',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<h4 style={{ color: '#222264' }}>{t('profile.1')}</h4>
					<Typography
						style={{
							whiteSpace: 'pre-line',
							overflowWrap: 'break-word',
							wordWrap: 'break-word',
							hyphens: 'auto',
						}}
					>
						{profileData.firstname}
					</Typography>
					<br />
					<h4 style={{ color: '#222264' }}>{t('profile.2')}</h4>
					<Typography
						style={{
							whiteSpace: 'pre-line',
							overflowWrap: 'break-word',
							wordWrap: 'break-word',
							hyphens: 'auto',
						}}
					>
						{profileData.lastname}
					</Typography>
					<br />
					<h4 style={{ color: '#222264' }}>{t('profile.3')}</h4>
					<Typography
						style={{
							whiteSpace: 'pre-line',
							overflowWrap: 'break-word',
							wordWrap: 'break-word',
							hyphens: 'auto',
						}}
					>
						{profileData.email}
					</Typography>
					<br />
					<h4 style={{ color: '#222264' }}>{t('profile.12')}</h4>
					<Table sx={{ width: '100%', maxWidth: '500px' }} aria-label="simple table">
						<TableBody>
							{bookings.map((film) => (
								<TableRow
									key={film.id}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<TableCell align="left" sx={{ paddingRight: "3px" }}>
										<a href={`/movie/${film.id}`}>{language === 'fi' ? film.title : film.title_en}</a>
									</TableCell>
									<TableCell align="left" sx={{ padding: "3px" }}>
										{film.screenday} {film.screentime}
									</TableCell>
									<TableCell align="left" sx={{ padding: "3px" }}>
										<Button onClick={() => handleCancelDelete(film)} style={{ color: "#ea5b0c" }}>
											{t('timetable.9')}
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					<br />
				</Container>
				<Container
					sx={{
						pb: '5',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
						flexDirection: 'column',
					}}
				>
					<Box sx={{ flexGrow: 2 }}>
						<Button
							theme={theme}
							onClick={() => navigate('/settings')}
						>
							{t('profile.4')}
						</Button>
						<Button theme={theme}>
							<label
								htmlFor="set_profilepic"
								className="styled-image-upload"
							>
								{t('profile.5')}
							</label>
							<input
								type="file"
								name="file"
								id="set_profilepic"
								accept="image/jpeg, image/png, image/jpg"
								onChange={setProfilePicture}
							></input>
						</Button>
						<Button
							theme={theme}
							onClick={() => navigate('/changepassword')}
						>
							{t('profile.6')}
						</Button>
					</Box>
				</Container>
				<br />
				<Container sx={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						theme={deleteTheme}
						variant="contained"
						// style={{ backgroundColor: "#ea5b0c" }}
						onClick={() => deleteUser()}
					>
						{t('profile.7')}
					</Button>
				</Container>
			</Paper>
			<Notification />
		</Container>
	);
};

export default Profile;
