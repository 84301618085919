import '../css/VideoPlayer.css';
import { useRef } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Container, Box } from '@mui/material';
import { PlayCircleFilledWhiteOutlined } from '@mui/icons-material';

const VideoPlayer = ({ imdb_id, festivalData, t }) => {
	const playerRef = useRef(null);
	const buffering = useRef(false);

	let stream_url = festivalData.trailer;

	return (
		<>
			<Container maxWidth="md" >
				<Box sx={{ display: 'flex', justifyContent: 'center ' }}>
					<ReactPlayer
						ref={playerRef}
						playing={false}
						autoPlay={true}
						controls={buffering.current === false}
						pip={false}
						url={stream_url}
						// height="auto"
						// width="auto"
						playIcon={
							<PlayCircleFilledWhiteOutlined fontSize="large" />
						}
					/>
				</Box>
			</Container>
		</>
	);
};

export default VideoPlayer;
