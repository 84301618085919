import { createSlice } from "@reduxjs/toolkit";
import bookingService from "../services/bookingService";

const filmsSlice = createSlice({
	name: "films",
	initialState: null,
	reducers: {
		setFilms(state, action) {
			const content = action.payload;
			return content;
		}
	},
});

export const { setFilms } = filmsSlice.actions;

export const getFilms = () => {
	return async (dispatch) => {
		const films = await bookingService.getFilms();
		if (films) {
			dispatch(setFilms(films));
		} else {
			dispatch(setFilms([]));
		}
	};
};

export default filmsSlice.reducer;
