import backgroundImg from '../images/OEJ_bg+logo_hd_keskitetty.jpg';
import {
	Container,
	Typography,
	Button,
	Grid,
	ImageList,
	ImageListItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import AspectRatio from '@mui/joy/AspectRatio';

const Frontpage = ({ t }) => {
	return (
		<>
			<Container
				maxWidth="100%"
				sx={{
					minHeight: '84.5vh',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'between',
					alignItems: 'center',
					backgroundImage: `url(${backgroundImg})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					// paddingBottom: '50px',
					"@media screen and (orientation: landscape) and (min-height: 1000px)": {
						minHeight: '88.5vh',
					},
					"@media screen and (orientation: portrait) and (min-height: 1100px)": {
						minHeight: '88.5vh',
					},
					"@media screen and (orientation: portrait) and (max-height: 900px)": {
						minHeight: '84vh',
					},
					"@media screen and (orientation: portrait) and (max-height: 850px)": {
						minHeight: '83vh',
					},
					"@media screen and (orientation: portrait) and (max-height: 800px)": {
						minHeight: '80vh'
					},
					// backgroundRepeat: 'repeat',
				}}
			>
				{/* <Logo
					fill="#FFFFFF"
					style={{
						width: '300px',
						maxWidth: '80vw',
						margin: 'auto',
						marginTop: '20px',
					}}
				/> */}
				{/* <Typography
					// align="center"
					sx={{
						color: 'white',
						margin: 'auto',
						marginTop: '90vh',
						fontWeight: '700',
						fontSize: 15,
						maxFontSize: '10px',
						maxWidth: '90vw',
					}}
				>
					14. - 16.7.2023
					{t('landing.1')}
				</Typography> */}
				<Button
					to="/login"
					component={Link}
					variant="contained"
					color="primary"
					sx={{
						backgroundColor: '#222264',
						margin: 'auto',
						marginTop: '78vh',
						textAlign: 'center',
						width: '300px',
						maxWidth: '80vw',
						maxHeight: '50px',
						"@media screen and (orientation: portrait)": {
								marginTop: '75vh'
						},
						"@media screen and (orientation: landscape) and (max-height: 800px)": {
							marginTop: '82vh',
						},
						"@media screen and (orientation: landscape) and (min-height: 1000px)": {
							marginTop: '83vh',
						},
						"@media screen and (orientation: portrait) and (min-height: 1100px)": {
							marginTop: '77vh',
						},
						"@media screen and (orientation: portrait) and (max-height: 900px)": {
							marginTop: '73vh',
						},
						"@media screen and (orientation: portrait) and (max-height: 800px)": {
							marginTop: '70vh'
						},
						// height: '50px',
					}}
				>
					{t('landing.2')}
				</Button>
			</Container>
			{/* <Container
				maxWidth="100%"
				sx={{
					display: 'flex',
					backgroundColor: 'black',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<Grid>
					<Typography
						align="center"
						sx={{
							color: 'white',
							margin: 'auto',
							fontWeight: 700,
							fontSize: 20,
						}}
					>
						{t('landing.3')}
					</Typography>
					<Typography
						align="center"
						sx={{
							color: 'white',
							margin: 'auto',
							fontSize: 20,
						}}
					>
						{t('landing.4')}
					</Typography>
				</Grid>
				<Grid
					item
					sx={{
						display: 'flex',
						justifyContent: 'center',
						flexGrow: 1,
					}}
				>
					<AspectRatio
						ratio={1}
						sx={{
							maxWidth: '200px',
							width: '20vw',
							mt: 1,
							mb: 1,
							ml: 10,
						}}
					>
						<img
							src={bittorrent}
							style={{ width: '100%' }}
							alt="bittorrent"
						/>
					</AspectRatio>
				</Grid>
			</Container> */}
			{/* <Container maxWidth="lg" sx={{ height: '100%', pb: 2 }}>
				<Typography
					align="center"
					sx={{
						color: 'white',
						m: 'auto',
						fontWeight: 700,
						fontSize: 20,
						mt: 1,
						mb: 1,
					}}
				>
					{t('landing.5')}
				</Typography> */}
				{/* <ImageList
					sx={{ width: '100%', height: '100%' }}
					cols={5}
					rowHeight="auto"
				>
					<ImageListItem>
						<img src={cover1} alt="cover1" />
					</ImageListItem>
					<ImageListItem>
						<img src={cover2} alt="cover2" />
					</ImageListItem>
					<ImageListItem>
						<img src={cover3} alt="cover3" />
					</ImageListItem>
					<ImageListItem>
						<img src={cover4} alt="cover4" />
					</ImageListItem>
					<ImageListItem>
						<img src={cover5} alt="cover5" />
					</ImageListItem>
				</ImageList> */}
			{/* </Container> */}
		</>
	);
};

export default Frontpage;
