import MovieIcon from '@mui/icons-material/Movie';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	Box,
	Card,
	CardContent,
	CardMedia,
	Typography,
	CardActionArea,
} from '@mui/material';
import cover1 from '../../images/film_covers/mystery.jpg';
import cover2 from '../../images/film_covers/uefa.jpg';
import cover3 from '../../images/film_covers/3.jpg';
import cover4 from '../../images/film_covers/4.jpg';
import cover5 from '../../images/film_covers/5.jpg';
import cover6 from '../../images/film_covers/uefa.jpg';
import cover7 from '../../images/film_covers/7.jpg';
import cover8 from '../../images/film_covers/8_withtext.jpg';
import cover9 from '../../images/film_covers/9.jpg';
import cover10 from '../../images/film_covers/10.jpg';

const MovieList = ({ movies, booked, t }) => {
	const navigate = useNavigate();
	const language = useSelector((state) => state.language);

	const navigateToMovie = (movie_id) => {
		navigate(`/movie/${movie_id}`);
	};

	return (
		<Box
			container="true"
			spacing={3}
			style={{
				direction: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				display: 'flex',
				flexWrap: 'wrap',
				width: '100%',
				height: '100%',
				marginTop: 25
			}}
		>
			{movies.map((movie, value) => {
				let selectedCover;
				switch (movie.festival_data.id) {
					case 1:
						selectedCover = cover1;
						break;
					case 2:
						selectedCover = cover2;
						break;
					case 3:
						selectedCover = cover3;
						break;
					case 4:
						selectedCover = cover4;
						break;
					case 5:
						selectedCover = cover5;
						break;
					case 6:
						selectedCover = cover6;
						break;
					case 7:
						selectedCover = cover7;
						break;
					case 8:
						selectedCover = cover8;
						break;
					case 9:
						selectedCover = cover9;
						break;
					case 10:
						selectedCover = cover10;
						break;
					default:
						selectedCover = null;
				}
				return (
					<Box
						sx={{
							margin: 1,
							marginBottom: 3,
							maxWidth: 300,
							maxHeight: 450
						}}
						key={value}
						item="true"
						xs={3}
						onClick={() => navigateToMovie(movie.festival_data.id)}
					>
						<Card className="container" sx={{ flexGrow: 1 }}>
							<CardActionArea>
								<CardContent
									style={{
										textOverflow: 'ellipsis',
									}}
									className="newsletter"
								>
									<Typography
										gutterBottom
										variant="h6"
										style={{
											whiteSpace: 'pre-line',
											overflowWrap: 'break-word',
											wordWrap: 'break-word',
											hyphens: 'auto',
											overflow: 'hidden',
										}}
									>
										{language === 'fi' ? movie.festival_data.title : movie.festival_data.title_en} ({movie.festival_data.year})
									</Typography>
									<Typography>
										{t('browsing.14')}: {movie.festival_data.screenday} {movie.festival_data.screentime}
									</Typography>
									{booked &&
										booked.includes(movie.festival_data.id) ? (
										<Box
											style={{
												display: 'flex',
												alignItems: 'center',
												flexWrap: 'wrap',
											}}
										>
											<Typography style={{ marginRight: 4, marginTop: 3 }}>
												<MovieIcon />{' '}
											</Typography>
											<Typography>{t('browsing.15')}</Typography>
										</Box>
									) : null}
								</CardContent>
								<CardMedia
									sx={{
										borderRadius: 1,
										width: 300,
										height: 450,
									}}
									component="img"
									image={selectedCover}
									alt={movie.festival_data.title}
									onError={(e) => {
										e.target.onerror = null;
										e.target.src = require('../../images/no_image.png');
									}}
								/>
							</CardActionArea>
						</Card>
					</Box>
				)
			})};
		</Box>
	);
};

export default MovieList;
