import axios from "axios";
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/booking`;

const getFilms = () => {
	const request = axios.get(`${baseUrl}/films`);
	return request.then((response) => response.data);
};

const makeBooking = (film_id) => {
	const request = axios.post(`${baseUrl}/${film_id}`);
	return request.then((response) => response.data);
};

const cancelBooking = (film_id) => {
	const request = axios.delete(`${baseUrl}/${film_id}`);
	return request.then((response) => response.data);
};

const bookingService = { getFilms, makeBooking, cancelBooking };

export default bookingService;
