import axios from "axios";
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/admin`;

const getReservations = () => {
	const request = axios.get(`${baseUrl}/reservations`);
	return request.then((response) => response.data);
};

const sendEmails = (date) => {
	const request = axios.post(`${baseUrl}/sendEmails`, { date });
	return request.then((response) => response.data);
};

const bookingService = { getReservations, sendEmails };

export default bookingService;
