import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Typography, Paper, Box, Grid, Avatar, Button } from '@mui/material';
import { Container } from '@mui/system';
import Notification from './Notification';
import { useDispatch, useSelector } from 'react-redux';
import { changeSeverity } from '../reducers/severityReducer';
import { changeNotification } from '../reducers/notificationReducer';
import { setProfileData } from '../reducers/profileReducer';
import bookingService from '../services/bookingService';
import Loader from './Loader';

const BasicTable = ({ movies, userBookings, makeBooking, cancelBooking, t }) => {
	const language = useSelector((state) => state.language);
	const navigate = useNavigate();

	return (
		<TableContainer component={Paper}>
			<Table sx={{ width: '100%' }} aria-label="simple table">
				<TableHead>
					<TableRow  >
						<TableCell sx={{ paddingLeft: "1vw", paddingRight: 0 }}>{t('timetable.4')}</TableCell>
						{/* <TableCell align="left" sx={{ padding: "3px" }}>{t('timetable.4')}</TableCell> */}
						<TableCell align="left" sx={{ padding: "3px" }}>{t('timetable.5')}</TableCell>
						<TableCell align="left" sx={{ padding: "3px" }}>{t('timetable.6')}</TableCell>
						<TableCell align="left" sx={{ padding: "3px" }}>{t('timetable.7')}</TableCell>
						<TableCell align="left" sx={{ padding: "3px" }}>{t('timetable.8')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{movies.map((movie) => {
						const booked = userBookings.find((booking) => booking === movie.id)
						return (<TableRow
							key={movie.id}
						// sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<TableCell component="th" scope="row" sx={{ paddingLeft: "1vw", paddingRight: 0 }}>
								{movie.screenday} {movie.screentime}
							</TableCell>
							{/* <TableCell align="left" sx={{ padding: "3px" }}>{movie.screentime}</TableCell> */}
							{(movie.title.includes('YLLÄTYS') || movie.title.includes('Jalkapallon') || movie.title.includes('Kinokonsertti')) && (
								<TableCell align="left" sx={{ padding: "3px" }}
									style={{ cursor: 'pointer' }} onClick={() => navigate(`/movie/${movie.id}`)}>
									{language === 'fi' ? movie.title.toUpperCase() : movie.title_en.toUpperCase()} ({movie.year})
								</TableCell>
							)}
							{movie.title.includes('Joan Baez') && (
								<TableCell align="left" sx={{ padding: "3px" }}
									style={{ cursor: 'pointer' }} onClick={() => navigate(`/movie/${movie.id}`)}>
									{"Navasky, O'Boyle, O'Connor: "} {movie.title.toUpperCase()} ({movie.year})
								</TableCell>
							)}
							{movie.title.includes('Ninja') && (
								<TableCell align="left" sx={{ padding: "3px" }}
									style={{ cursor: 'pointer' }} onClick={() => navigate(`/movie/${movie.id}`)}>
									{language === 'fi' ? "NINJOJEN YÖ: " : "NIGHT OF THE NINJAS: "} Golan: ENTER THE NINJA ({movie.year})
								</TableCell>
							)}
							{!movie.title.includes('YLLÄTYS') && !movie.title.includes('Jalkapallon') && !movie.title.includes('Kinokonsertti') && !movie.title.includes('Joan Baez') && !movie.title.includes('Ninja') && (
								<TableCell align="left" sx={{ padding: "3px" }}
									style={{ cursor: 'pointer' }} onClick={() => navigate(`/movie/${movie.id}`)}>
									{movie.director.split(" ")[1]}: {language === 'fi' ? movie.title.toUpperCase() : movie.title_en.toUpperCase()} ({movie.year})
								</TableCell>
							)}
							<TableCell align="left" sx={{ padding: "3px" }}>{movie.duration} min</TableCell>
							<TableCell align="left" sx={{ padding: "3px", minWidth: '50px' }}>{movie.freeseats} / 20</TableCell>
							<TableCell align="left" sx={{ paddingLeft: "3px", paddingRight: "1vw" }}>
								{booked &&
									<Button variant="contained" style={{ maxWidth: '20vw', backgroundColor: "#ea5b0c" }}
										onClick={() => cancelBooking(movie.id)} > {t('timetable.9')}
									</Button>}
								{!booked && movie.freeseats > 0 &&
									<Button variant="contained" style={{ maxWidth: '20vw', backgroundColor: "#222264" }}
										onClick={() => makeBooking(movie.id)} > {t('timetable.8')}
									</Button>}
								{!booked && movie.freeseats === 0 &&
									<Button disabled variant="contained" style={{ maxWidth: '20vw' }}
									> {t('timetable.10')}
									</Button>}
							</TableCell>
						</TableRow>)
					})}
				</TableBody>
			</Table>
			<Typography sx={{ display: 'flex', paddingLeft: "1vw", paddingRight: "1vw", justifyContent: 'left', mt: 2, mb: 2 }}>
				{t('timetable.14')}
			</Typography>
		</TableContainer>
	);
}

const Timetable = ({ t }) => {
	const [movies, setMovies] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const profile = useSelector((state) => state.profile)
	const language = useSelector((state) => state.language)
	const userBookings = profile?.bookings || []

	useEffect(() => {
		bookingService.getFilms().then((response) => {
			setMovies(response);
			setIsLoading(false);
		});
	}, []);

	const makeBooking = (film_id) => {
		bookingService.makeBooking(film_id).then((response) => {
			if (response === 'User not signed in') {
				dispatch(changeSeverity('error'));
				dispatch(changeNotification(`${t('timetable.1')}`));
				navigate('/login');
			} else {
				dispatch(setProfileData({ ...profile, bookings: [...profile.bookings, film_id] }))
				setMovies(movies.map((movie) => movie.id === film_id ? { ...movie, freeseats: movie.freeseats - 1 } : movie))
				const movie = movies.find((movie) => movie.id === film_id)
				dispatch(changeSeverity('success'));
				dispatch(changeNotification(`${t('timetable.11')} ${language === 'fi' ? movie.title.toUpperCase() : movie.title_en.toUpperCase()}. ${t('timetable.13')}`));
			}
			console.log(response);
		})
	};

	const cancelBooking = (film_id) => {
		bookingService.cancelBooking(film_id).then((response) => {
			if (response === 'User not signed in') {
				dispatch(changeSeverity('error'));
				dispatch(changeNotification(`${t('timetable.2')}`));
				navigate('/login');
			} else {
				dispatch(setProfileData({ ...profile, bookings: profile.bookings.filter((booking) => booking !== film_id) }))
				setMovies(movies.map((movie) => movie.id === film_id ? { ...movie, freeseats: movie.freeseats + 1 } : movie))
				const movie = movies.find((movie) => movie.id === film_id)
				dispatch(changeSeverity('success'));
				dispatch(changeNotification(`${t('timetable.12')} ${language === 'fi' ? movie.title.toUpperCase() : movie.title_en.toUpperCase()}.`));
			}
			console.log(response);
		})
	};

	if (isLoading || movies.length === 0) return (<Loader />);

	return (
		<Container maxWidth="md" sx={{ pt: 5, pb: 5 }}>
			<BasicTable movies={movies} userBookings={userBookings} makeBooking={makeBooking} cancelBooking={cancelBooking} t={t} />
			<Notification />
		</Container >
	);
}

export default Timetable;
